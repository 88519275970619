// -------------------------------------------------------------------- Function

/**
 * Expect a value to be defined
 * - Throws an Exception if the parameter [value] is undefined
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectDefined( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value === "undefined" )
  {
    throw new Error(
      extend_error_text(errorText, "(expected a defined value)") );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a string
 * - Throws an Exception if the parameter [value] is not a string
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectString( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "string" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected string, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a not-empty string
 * - Throws an Exception if the parameter [value] is not a string
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectNotEmptyString( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "string" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected not empty string, got ${type(value)})`) );
  }

  if( value === "" )
  {
    throw new Error(
      extend_error_text(
        errorText, "(expected not empty string, got empty string)") );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a Symbol
 * - Throws an Exception if the parameter [value] is not a Symbol
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectSymbol( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "symbol" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected symbol, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a number
 * - Throws an Exception if the parameter [value] is not a number
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectNumber( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "number" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected number, got ${type(value)})`) );
  }

  if( isNaN(value) )
  {
    throw new Error(
      extend_error_text(
        errorText, "(expected number, got [NaN])") );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a number and greater than zero
 * - Throws an Exception if the parameter [value] is not a number
 *   or 0 or negative
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectPositiveNumber( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "number" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected number, got ${type(value)})`) );
  }

  if( value <= 0 )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected positive number, got [${value}])`) );
  }

  if( isNaN(value) )
  {
    throw new Error(
      extend_error_text(
        errorText, "(expected number, got [NaN])") );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a boolean
 * - Throws an Exception if the parameter [value] is not a boolean
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectBoolean( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "boolean" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected boolean, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be iterable (should implement the iterator protocol)
 * - Throws an Exception if the parameter [value] otherwise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 *
 * @see
 *    https://developer.mozilla.org/en-US/
 *    docs/Web/JavaScript/Reference/Iteration_protocols
 *
 * @see
 *    https://stackoverflow.com/questions/6863182/
 *    what-is-the-difference-between-iterator-and-iterable-and-how-to-use-them
 */
export function expectIterable( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Object) ||
      typeof value[ Symbol.iterator ] !== "function" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected iterable, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Iterator object
 * - Throws an Exception if the parameter [value] otherwise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 *
 * @see
 *    https://developer.mozilla.org/en-US/
 *    docs/Web/JavaScript/Reference/Iteration_protocols
 *
 * @see
 *    https://stackoverflow.com/questions/6863182/
 *    what-is-the-difference-between-iterator-and-iterable-and-how-to-use-them
 */
export function expectIterator( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Object) ||
       typeof value.next !== "function" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected iterator, got ${type(value)})`) );
  }

  // if( !(value instanceof Object) ||
  //     typeof value[ Symbol.iterator ] !== "function" )
  // {
  //   throw new Error(
  //     extend_error_text(
  //       errorText, `(expected iterator, got ${type(value)})`) );
  // }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to implement the iterator protocol
 * - Throws an Exception if the parameter [value] otherwise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 *
 * @see
 *    https://developer.mozilla.org/en-US/
 *    docs/Web/JavaScript/Reference/Iteration_protocols
 *
 * @see
 *    https://stackoverflow.com/questions/6863182/
 *    what-is-the-difference-between-iterator-and-iterable-and-how-to-use-them
 */
export function expectAsyncIterator( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Object) ||
      typeof value[ Symbol.asyncIterator ] !== "function" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected async iterator, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Array
 * - Throws an Exception if the parameter [value] is not an Array
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectArray( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Array) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected array, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Array that only contains strings
 * - Throws an Exception if the parameter [value] is invalid
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectArrayOfStrings( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Array) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected array of strings, got ${type(value)})`) );
  }

  // -- Check if all array items are strings

  for( let j = 0, n = value.length; j < n; j = j + 1 )
  {
    if( typeof value[j] !== "string" )
    {
      throw new Error(
        extend_error_text(
          errorText,
          `(expected array[${j}] to be a string, ` +
          `got ${type(value[j])})`) );
    }
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a string or an Array that only contains strings
 * - Throws an Exception if the parameter [value] is invalid
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectStringOrArrayOfStrings( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Array) )
  {
    if( typeof value === "string" )
    {
      // A string is ok -> done
      return;
    }

    throw new Error(
      extend_error_text(
        errorText,
        `(expected string or array of strings, got ${type(value)})`) );
  }

  // -- Check if all array items are strings

  for( let j = 0, n = value.length; j < n; j = j + 1 )
  {
    if( typeof value[j] !== "string" )
    {
      throw new Error(
        extend_error_text(
          errorText,
          `(expected array value [${j}] to be a string, ` +
          `got ${type(value[j])})`) );
    }
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an object path
 * - Throws an Exception if the parameter [value] is not an object path
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectObjectPath( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value === "string" )
  {
    return;
  }

  if( !(value instanceof Array) )
  {
    throw new Error(
      extend_error_text(
        errorText,
        `(expected object path (string or array of strings), ` +
        `got ${type(value)})`) );
  }

  for( let j = 0, n = value.length; j < n; j = j + 1 )
  {
    if( typeof value[j] !== "string" )
    {
      throw new Error(
        extend_error_text(
          errorText,
          `(expected array value [${j}] to be a string, ` +
          `got ${type(value[j])})`) );
    }
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a Set
 * - Throws an Exception if the parameter [value] is not a Set
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectSet( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Set) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected Set, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a Set
 * - Throws an Exception if the parameter [value] is not a Set
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectSetOfStrings( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Set) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected Set, got ${type(value)})`) );
  }

  for( let current of value.values() )
  {
    if( typeof current !== "string" )
    {
      throw new Error(
        extend_error_text(
          errorText,
          `(expected all items in set to be a strings, ` +
          `got ${type(current)})`) );
    }
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Object
 * - Throws an Exception if the parameter [value] is not an Object
 * - Throws an exception if the parameter is a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectObject( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Object) ||
       (value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected object, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Object or null
 * - Throws an Exception if the parameter [value] is not an Object or null
 * - Throws an exception if the parameter is a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectObjectOrNull( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( value === null )
  {
    return;
  }

  if( !(value instanceof Object) ||
      (value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected object or null, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Object or undefined
 * - Throws an Exception if the parameter [value] is not an Object or null
 * - Throws an exception if the parameter is a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectObjectOrUndefined( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( value === undefined )
  {
    return;
  }

  if( !(value instanceof Object) ||
      (value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected object or undefined, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Object, but not an array
 * - Throws an Exception if the parameter [value] is not an Object or
 *   is an Array.
 * - Throws an exception if the parameter is a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
function expectObjectNoArray( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( Array.isArray(value) )
  {
    throw new Error(
      extend_error_text(errorText, "(expected Object but no Array)") );
  }

  if( !(value instanceof Object) ||
      (value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected object, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an Object, but not a function
 * - Throws an Exception if the parameter [value] is not an Object or
 *   is a function.
 * - Throws an exception if the parameter is a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectObjectNoFunction( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value === "function" )
  {
    throw new Error(
      extend_error_text(errorText, "(expected Object but no function)") );
  }

  if( !(value instanceof Object) ||
      (value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected object, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be a promise
 * - Throws an Exception if the parameter [value] is not a Promise
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectPromise( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof Promise) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected Promise, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be defined
 * - Throws an Exception if the parameter [value] is undefined
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectFunction( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( typeof value !== "function" )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected function, got ${type(value)})`) );
  }
};

// -------------------------------------------------------------------- Function

/**
 * Expect a value to be an ArrayBuffer
 * - Throws an Exception if the parameter [value] is not an ArrayBuffer
 *
 * @param {mixed} value - Value to check
 * @param {string} errorText - Text of the error to throw
 */
export function expectArrayBuffer( value, errorText )
{
  if( typeof errorText !== "string" )
  {
    throw new Error(
      "Missing or invalid parameter [errorText] (expected string)");
  }

  if( !(value instanceof ArrayBuffer) )
  {
    throw new Error(
      extend_error_text(
        errorText, `(expected ArrayBuffer, got ${type(value)})`) );
  }
};

// ----------------------------------------------------------- Helper function

/**
 * Append extra "(expected..)" error information to the error text
 * - Only appends the text if not already in the original error text
 *
 * @param {string} errorText - Original error text
 * @param {string} expectedText - Text to append if missing
 *
 * @return {string} original or extended error text
 */
function extend_error_text( errorText, expectedText )
{
  if( -1 === errorText.indexOf("(expected") )
  {
    errorText = errorText + " " + expectedText;
  }

  return errorText;
}

// ----------------------------------------------------------- Helper function

/**
 * Returns the type of the supplied value, like "typeof", but a bit more
 * detailed:
 * - returns "null" for null
 * - TODO: returns "ArrayBuffer" for ArrayBuffer
 *
 * @param {mixed} value [description]
 *
 * @return {string} Type of the supplied value
 */
function type( value )
{
  var type = typeof value;

  switch( type )
  {
    case "object":
      if( value === null )
      {
        return "null";
      }
      else if( value instanceof Promise )
      {
        return "Promise";
      }
      // else if( typeof value.next === "function" )
      // {
      //   return "Iterator";
      // }
      // else if( value instanceof ArrayBuffer )
      // {
      //   return "ArrayBuffer";
      // }

      return value;

    default:
      return type;
  }
}
