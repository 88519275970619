import { objectSet } from "../../helpers/Objects";

// -------------------------------------------------------------------- Function

export const getFieldValue = (state, fieldName) => {
  if (!state.formData.fields || !state.formData.fields[fieldName]) {
    return "";
  }

  return state.formData.fields[fieldName];
};

// -------------------------------------------------------------------- Function

export const getAllFieldValues = (state) => {
  if ( !state.formData ) {
    return {};
  }

  return state.formData.fields;
};

// -------------------------------------------------------------------- Function

export const getCreateTaskData = (state) => {
  if (!state.formData.fields) {
    return null;
  }

  const createTaskData = {
    client: {},
    location: {},
    task: {},
  };

  for (const key in state.formData.fields) {
    const value = state.formData.fields[key];

    switch (key)
    {
      case "companyName":
      case "firstName":
      case "lastName":
      case "emailAddress":
      case "phoneNumber":
        createTaskData.client[key] = value;

        break;

      case "street":
      case "streetNumber":
      case "streetNumberSuffix":
      case "postalCode":
      case "gpsLatitude":
      case "gpsLongitude":
      case "place":
      case "country":
        createTaskData.location[key] = value;

        break;

      default:

        if ( key.indexOf("taskProps.") !== -1 )
        {
          objectSet(createTaskData, `task.${key}`, value);
        }
        else
        {
          createTaskData.task[key] = value;
        }
        break;
    }
  }

  return createTaskData;
};
