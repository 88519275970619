import { SET_VALUE_SUCCESS } from "./actions";

const initialState = {
  fields: {},
};

// -------------------------------------------------------------------- Function

export default function forms(state = initialState, { type, payload }) {
  switch (type) {
    case SET_VALUE_SUCCESS: {
      const { fieldName, value } = payload;

      const returnObject = Object.assign(
        {
          ...state,
          fields: { ...state.fields }
        }
      );

      returnObject.fields[fieldName] = value;

      return returnObject;
    }

    default:
      return { ...state };
  }
}
