// React
import { useDispatch, useSelector } from "react-redux";

// Syncfusion
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

// Store
import { setValue } from "../../../store/formData/actions";
import { getFieldValue } from "../../../store/formData/selectors";
import { fieldValid } from "../../../store/pages/selectors";

// Style
import "./Textbox.scss";

export default function Textbox({
  formName,
  fieldName,
  widgetData,
  parseInfo,
}) {
  const dispatch = useDispatch();

  const { placeholder, inputType } = widgetData;

  const value = useSelector((state) => getFieldValue(state, fieldName));

  function change({ value }) {
    dispatch(setValue({ formName, fieldName, value }));
  }

  const { isValid, showValidation } =
    useSelector((state) => fieldValid( state, fieldName ));

  const multiline = inputType === "multiLineText" ? true : false;

  return (
    <div className={ (!isValid && showValidation ) ? "text-box form-field invalid" : "text-box form-field" }>
      <TextBoxComponent
        placeholder={placeholder}
        type={inputType}
        input={change}
        multiline={multiline}
        value={value}
      />
    </div>
  );
}
