// React
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Services
import { createTask } from "../../services/Task";

// Widgets
import Progress from "../../components/widgets/progress/Progress";
import Message from "../../components/widgets/message/Message";

// Stores
import { getCreateTaskData } from "../../store/formData/selectors";

// Styles
import "./CreateTask.scss";

// -------------------------------------------------------------------- Function

/**
 * TaskPage component
 */
export default function CreateTask() {
  const [messageLabel, setMessageLabel] = useState( "loading" );
  const [Component, setComponent] = useState();
  const createTaskData = useSelector(getCreateTaskData);

  useEffect(() => {
    const component = (
    <Message messageLabel={messageLabel}
             loading={ ( messageLabel === "loading" ? true : false )} /> );

    setComponent( component );
  }, [ messageLabel ]);

  useEffect(() => {
    const create_task = async (createTaskData) => {
      try{
        const response = await createTask(createTaskData);

        if( !response.ok )
        {
          throw new Error( "Create task failed" );
        }

        setMessageLabel("completed");
      }
      catch( error )
      {
        setMessageLabel("error");
      }
    };

    create_task(createTaskData);
  }, []);

  return (
    <div className="create-task-page">
      <div className="content">
        <Progress />
        <div className="form">
          {Component}
        </div>
      </div>
    </div>
  );
}
