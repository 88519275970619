// React
import { useDispatch, useSelector } from "react-redux";

// Syncfusion
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

// Store
import { setValue } from "../../../store/formData/actions";
import { getFieldValue } from "../../../store/formData/selectors";
import { fieldValid } from "../../../store/pages/selectors";

// Style
import "./DatePicker.scss";

export default function DatePicker({
  formName,
  fieldName,
  widgetData,
}) {
  const dispatch = useDispatch();

  const { placeholder, label } = widgetData;

  const value =
    useSelector( ( state ) => getFieldValue(state, fieldName));

  function change({ value })
  {
    if( value )
    {
      value = value.getTime();
    }

    dispatch(setValue({ formName, fieldName, value }));
  }

  const { isValid, showValidation } =
    useSelector((state) => fieldValid( state, fieldName ));

  const minDate = new Date();

  return (
    <div className={ (!isValid && showValidation ) ? "form-field invalid" : "form-field" }>
      <DatePickerComponent
        min={minDate}
        strictMode={true}
        label={label}
        placeholder={placeholder}
        change={change}
        value= {new Date( value ) } />
    </div>
  );
}
