// React
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Services
import { createSession, sendAuthenticationMail } from "../../services/Session";

// Helpers
import { objectGet } from "../../helpers/Objects";

// Widgets
import Progress from "../../components/widgets/progress/Progress";
import Message from "../../components/widgets/message/Message";
import Timeslots from "../../components/widgets/timeslots/Timeslots";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

// Stores
import { fetchSuggestion,
         requestSuggestion,
         pickTimeslot,
         cancelSuggestion,
         generateExtraTimeslots }
  from "../../store/suggestion/actions";

import { getSuggestion } from "../../store/suggestion/selectors";

// Styles
import "./PickTimeslot.scss";

const delay = ms => new Promise(res => setTimeout(res, ms));

// -------------------------------------------------------------------- Function

/**
 * TaskPage component
 */
export default function PickTimeslot( {
  allowExtraTimeslots, allowPreferedDate, maxTimeslots } ) {
  const dispatch = useDispatch();

  const [ COMPONENT, set_component ] = useState();
  const [ SESSION_ID, set_session_id ] = useState();

  const [ LONG_POLL_INTERVAL, set_long_poll_interval ] = useState( null );
  const SEARCH = useLocation().search;

  const ACCESS_CODE = new URLSearchParams(SEARCH).get('uac');
  const USER_ID = new URLSearchParams(SEARCH).get('uid');
  const TASK_ID = new URLSearchParams(SEARCH).get('id');

  function try_clear_long_poll_interval()
  {
    if( LONG_POLL_INTERVAL )
    {
      clearInterval( LONG_POLL_INTERVAL );
      set_long_poll_interval( null );
    }
  }

  function request_suggestion( { taskId, sessionId, value = null } )
  {
    dispatch(
      requestSuggestion({
        taskId: TASK_ID,
        sessionId: SESSION_ID,
        startDate: value ? value.getTime() : null
      } )
    );
  }

  async function generate_extra_timeslots( { value } )
  {
    if( !value )
    {
      return;
    }

    const preferredDate = value.getTime();

    await delay(200);
    
    dispatch(
      generateExtraTimeslots( {
        suggestionId: SUGGESTION.suggestionId,
        sessionId: SESSION_ID ,
        preferredDate
      } )
    );
  }

  function pick_timeslot( e, timeslotId )
  {
    dispatch(
      pickTimeslot({ timeslotId, sessionId: SESSION_ID })
    );
  }

  function no_suggestion_selected( { requestExtraTimeslotsAccess } )
  {
    const { timeslots } = SUGGESTION;

    if( requestExtraTimeslotsAccess &&
        allowExtraTimeslots &&
        maxTimeslots > timeslots.length )
    {
      set_preference_component( { onChange: generate_extra_timeslots } );
    }
    else{
      start_cancel_suggestion();
    }
  }

  function start_cancel_suggestion()
  {
    const { suggestionId } = SUGGESTION;
    const sessionId = SESSION_ID;

    let comment;

    function change( { value } )
    {
      comment = value;
    }

    set_component(
      <div className="timeslots">
        <TextBoxComponent
          placeholder="Wat zijn uw specifieke voorkeuren?"
          input={change}
          multiline={true} />

        <div className="submit-button button active" >
          <div className="icon">
            <i className="fal fa-user-headset"></i>
          </div>

          <span onClick={(e) =>
            dispatch(
              cancelSuggestion( {
                suggestionId,
                sessionId,
                comment
              } )
            ) } >
              Voorkeuren versturen
          </span>
        </div>
      </div>
    );
  }

  function set_preference_component( { onChange } )
  {
    const { timeslots, maxPlannedDate } = SUGGESTION;

    let minDateMs;

    if( timeslots && timeslots.length )
    {
      for( const timeslot of timeslots )
      {
        if( !minDateMs || minDateMs > timeslot.plannedDate )
        {
          minDateMs = timeslot.plannedDate;
        }
      }
    }
    else{
      minDateMs = maxPlannedDate;
    }

    const minDate = new Date( minDateMs );

    if( allowPreferedDate )
    {
      set_component(
        <DatePickerComponent
          min={minDate}
          strictMode={true}
          placeholder="Voorkeursdatum"
          change={onChange} /> );
    }
    else{
      onChange( { value: minDate } );
    }
  }

  useEffect(() => {
    if( !ACCESS_CODE || !TASK_ID || !USER_ID )
    {
      return;
    }

    if( !SESSION_ID )
    {
      const create_session = async () =>
      {
        try{
          const session = await createSession( {
            accessCode: ACCESS_CODE
          } );

          const userGroups =
            objectGet( session, "_hkdUser.groupIds" );

          if( userGroups.indexOf( "clients" ) !== -1 ||
              userGroups.indexOf( "backoffice" ) !== -1 ||
              userGroups.indexOf( "teambessy" ) !== -1 )
          {
            set_session_id( session._hkdSid );
          }
          else{
            set_component(
              <div>
                <Message messageLabel="authentication-required" />

                <div className="submit-button button" onClick={ () =>
                  {
                    sendAuthenticationMail( {
                      userId: USER_ID, taskId: TASK_ID } )

                    set_component(
                      <div>
                        <Message messageLabel="authentication-message-send" />
                      </div>
                    );
                  }}>
                  <div className="icon">
                    <i className="fal fa-envelope"></i>
                  </div>

                  <div className="text">
                    Stuur link
                  </div>
                </div>
              </div>
            );
          }
        }
        catch( error )
        {
          set_component(
            <Message messageLabel="error" />
          );
        }
      };

      create_session();
    }
    else{
      dispatch(
        fetchSuggestion({
          taskId: TASK_ID,
          sessionId: SESSION_ID
        } )
      );
    }
  }, [ SESSION_ID, USER_ID, TASK_ID, ACCESS_CODE ]);

  const SUGGESTION = useSelector( getSuggestion );

  useEffect(() => {
    if( !SUGGESTION || !SUGGESTION.status )
    {
      set_component(
        <Message messageLabel="loading" /> );
      return;
    }

    const { access } = SUGGESTION;

    let requestSuggestionAccess = true;
    let requestExtraTimeslotsAccess = true;

    if( access && !access.requestSuggestionAccess.includes( "client" ) )
    {
      requestSuggestionAccess = false;
    }

    if( access && !access.requestExtraTimeslotsAccess.includes( "client" ) )
    {
      requestExtraTimeslotsAccess = false;
    }

    // Options beschikbaar
    if( SUGGESTION.status === "open" )
    {
      const { timeslots } = SUGGESTION;

      set_component(
        <div className="timeslots">
          <Timeslots  sessionId={SESSION_ID}
                      timeslots={timeslots}
                      pickTimeSlot={pick_timeslot}
                      />

          <div className={"submit-button button light"}
             onClick={() => no_suggestion_selected( { requestExtraTimeslotsAccess } ) }  >
            <div className="icon">
              <i className="fal fa-calendar-times"></i>
            </div>

            <div className="text">
              Deze opties komen mij niet uit
            </div>
          </div>
        </div> );
    }
    // Loading
    else if( SUGGESTION.status === "requested" )
    {
      set_component( <Message messageLabel="loading" /> );

      if( !LONG_POLL_INTERVAL )
      {
        const pollInterval = setInterval(
          () => {
            dispatch(
              fetchSuggestion({
                taskId: TASK_ID,
                sessionId: SESSION_ID
              } )
            );
          }, 5000
        );

        set_long_poll_interval( pollInterval );
      }
    }

    // No suggestion created yet
    else if( SUGGESTION.reason === "suggestion-not-found" )
    {
      if( requestSuggestionAccess )
      {
        request_suggestion( {
          sessionId: SESSION_ID,
          taskId: TASK_ID
        } )
      }
      else{
        set_component(
          <div>
            <Message messageLabel="no-timeslots-found" />
          </div>
        );
      }
    }

    // No timeslots found
    else if( SUGGESTION.reason === "no-timeslots-found" )
    {
      if( requestSuggestionAccess && allowPreferedDate )
      {
        set_component(
          <div>
            <Message messageLabel="no-timeslots-found" />

            <div className="submit-button button light" onClick={ () =>
              {
                start_cancel_suggestion()
              }}>
              <div className="icon">
                <i className="fal fa-calendar-edit"></i>
              </div>

              <div className="text">
                Voorkeuren doorgeven
              </div>
            </div>

            <div className="submit-button button light" onClick={ () =>
              {
                set_preference_component( { onChange: request_suggestion } )
              } }>
              <div className="icon">
                <i className="fal fa-calendar-week"></i>
              </div>

              <div className="text">
                Periode kiezen
              </div>
            </div>
          </div>
        )
      } 
      else{
        set_component(
          <div>
            <Message messageLabel="no-timeslots-found" />

            <div className="submit-button button light" onClick={ () =>
              {
                start_cancel_suggestion()
              }}>
              <div className="icon">
                <i className="fal fa-calendar-edit"></i>
              </div>

              <div className="text">
                Voorkeuren doorgeven
              </div>
            </div>
          </div>
        )
      }
    }
    else if( SUGGESTION.reason === "cancelled-by-user" ||
             SUGGESTION.reason === "expired" ||
             SUGGESTION.reason === "task-invalid" ||
             ( SUGGESTION.status === "picked" && !SUGGESTION.plannedDate ) )
    {
      let messageLabel;

      // Message label
      if( SUGGESTION.cancelledByClient )
      {
        messageLabel = "suggestion-cancelled-by-client";
      }
      else if( SUGGESTION.reason === "cancelled-by-user" )
      {
        messageLabel = "suggestion-cancelled";
      }
      else{
        messageLabel = "suggestion-expired";
      }

      // Request suggestion is allowed
      if( requestSuggestionAccess )
      {
        set_component(
          <div>
            <Message messageLabel={messageLabel} />
            <div className="submit-button button light" onClick={ () =>
              {
                request_suggestion( {
                  sessionId: SESSION_ID,
                  taskId: TASK_ID
                })
              } }>
              <div className="icon">
                <i className="fal fa-calendar-alt"></i>
              </div>

              <div className="text">
                Opties genereren
              </div>
            </div>
          </div>
        );
      }
      else{
        set_component(
          <div>
            <Message messageLabel={messageLabel} />
          </div>
        );
      }
    }
    else if( SUGGESTION.status && SUGGESTION.status !== "cancelled" )
    {
      let { messageLabel } = SUGGESTION;

      messageLabel =
        messageLabel ? messageLabel : SUGGESTION.status;

      set_component(
        <Message messageLabel={messageLabel} /> );
    }
    else{
      set_component(
        <Message messageLabel="error" /> );
    }

    if( SUGGESTION.status !== "requested" )
    {
      try_clear_long_poll_interval();
    }
  }, [ SUGGESTION, SESSION_ID ] );

  return (
    <div className="pick-timeslot">
      <div className="content">
        <Progress />
        <div className="form">
          { COMPONENT }
        </div>
      </div>
    </div>
  );
}
