// -------------------------------------------------------------------- Selector

/**
 * Get pages
 *
 * @param  {Object} state - Current state
 *
 * @return {Object} - Form object
 */
export const getPages = ( state ) => {

  return [ ...state.pages ];
};

// -------------------------------------------------------------------- Function

/**
 * Get current page
 *
 * @return {Object} { index, page }
 */
export const getCurrentPage = ( state ) =>
{
  const pages = [ ...state.pages ];

  let index;

  for( let j = 0, n = pages.length; j < n; j++ )
  {
    const page = pages[j];

    if( page.isCurrentPage ){
      index = j;
      break;
    }
  }

  const page = pages[index] || {};

  return { page, index };
}

// -------------------------------------------------------------------- Function

/**
 * Get current page
 *
 * @return {Object} { index, page }
 */
export const getCurrentValidation = ( state ) =>
{
  const { page } = getCurrentPage( state );

  if( !page || !page.validation )
  {
    return {}
  }

  return page.validation;
}

// -------------------------------------------------------------------- Function

/**
 * Get current page
 *
 * @return {Object} { index, page }
 */
export const fieldValid = ( state, fieldName ) =>
{
  const { page } = getCurrentPage( state );

  if( !page || !page.validation )
  {
    return { showValidation: false, isValid: false }
  }

  let { isValid, invalidFieldNames, showValidation } = page.validation

  if( isValid )
  {
    return { showValidation, isValid };
  }

  isValid = ( !invalidFieldNames || invalidFieldNames.indexOf( fieldName ) === -1 ) ? true : false;

  return { showValidation, isValid };
}

// -------------------------------------------------------------------- Function

/**
 * Get current page
 *
 * @return {Object} { index, page }
 */
export const getCurrentTemplate = ( state ) =>
{
  const { page } = getCurrentPage( state );
  if( !page )
  {
    return null;
  }

  return page.template;
}

// -------------------------------------------------------------------- Function

/**
 * Get next page
 *
 * @return {Object} { index, page } Next page
 */
export const getNextPage = ( state ) =>
{
  const pages = [ ...state.pages ];

  const { index: currentPageIndex } = getCurrentPage( state );

  let index = currentPageIndex + 1;

  let page;

  if( index >= 0 && index < pages.length )
  {
    page = pages[index];
  }
  else{
    index = null;
    page = null;
  }

  return { page, index };
}

// -------------------------------------------------------------------- Function

/**
 * Get previous page
 *
 * @return {Object} { index, page } Previous page
 */
export const getPreviousPage = ( state ) =>
{
  const pages = [ ...state.pages ];

  const { index: currentPageIndex } = getCurrentPage( state );

  let index = currentPageIndex - 1;
  let page;

  if( index >= 0 && index < pages.length )
  {
    page = pages[index];
  }
  else{
    index = null;
    page = null;
  }

  return { page, index };
}

// -------------------------------------------------------------------- Function

/**
 * Get the path of the previous page
 *
 * @return {String} Path
 */
export const getPreviousPagePath = ( state ) =>
{
  const { page } = getPreviousPage( state );

  if( page )
  {
    return page.path;
  }
  else{
    return null;
  }
}

// -------------------------------------------------------------------- Function

/**
 * Get the path of the next page
 *
 * @return {String} Path
 */
export const getNextPagePath = ( state ) =>
{
  const { page } = getNextPage( state );

  if( page )
  {
    return page.path;
  }
  else{
    return null;
  }
}
