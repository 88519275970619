// App actions
import { appLoading, appDoneLoading } from "../appState/actions";

// Services
import { getPages } from "../../services/Project";

// Dispatch types
export const FETCH_PAGES_SUCCESS = "FETCH_PAGES_SUCCESS";
export const SET_ACTIVE_SUCCESS = "SET_ACTIVE_SUCCESS";
export const SET_PAGE_VALIDATION_SUCCESS = "SET_PAGE_VALIDATION_SUCCESS";
export const SHOW_WIDGET_VALIDATION_SUCCESS = "SHOW_WIDGET_VALIDATION_SUCCESS";

// -------------------------------------------------------------------- Function

/**
 * Fetch pages from Bessy backend
 *
 * @param  {String} id - Id of the form
 */
export const fetchPages = ( currentPath ) => {
  return async ( dispatch, getState ) => {

    dispatch( appLoading() );

    try {
      const pages = await getPages();

      await dispatch({
        type: FETCH_PAGES_SUCCESS,
        payload: { pages, currentPath }
      });

      dispatch(appDoneLoading());

    } catch (err) {
      console.log(err.message);

      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Set active page
 *
 * @param  {String} id - Id of the form
 */
export const setActivePage = ( currentPath ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    try {
      await dispatch({
        type: SET_ACTIVE_SUCCESS,
        payload: { currentPath }
      });

      dispatch( appDoneLoading() );

    } catch (err) {
      console.log(err.message);

      dispatch( appDoneLoading() );
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Set active page
 *
 * @param  {String} id - Id of the form
 */
export const setShowWidgetValidation = ( index, showValidation ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    try {
      await dispatch({
        type: SHOW_WIDGET_VALIDATION_SUCCESS,
        payload: { index, showValidation }
      });

      dispatch(appDoneLoading());

    } catch (err) {
      console.log(err.message);

      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Set active page
 *
 * @param  {String} id - Id of the form
 */
export const setPageValidation = ( index, validation ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    try {
      await dispatch({
        type: SET_PAGE_VALIDATION_SUCCESS,
        payload: { index, validation }
      });

      dispatch(appDoneLoading());

    } catch (err) {
      console.log(err.message);

      dispatch(appDoneLoading());
    }
  };
};
