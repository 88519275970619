import { expectObject } from "./Expect"

/**
 * Extend an error object
 * - Adds properties to the Error object
 * - Deletes properties from the Error object
 * - Adds changed properties to a "history" property of the Error object
 *
 * @param {Error} exception - Error object
 *
 * @param {object} keyValuePairs
 *   Key value pairs to add to the Error object. If a value is undefined or
 *   null, the value will be deleted from the Error object.
 */
export function extendError( exception, keyValuePairs )
{
  if( !(exception instanceof Error) )
  {
    throw new Error(
      "Missing or invalid parameter [exception] (expected exception)" );
  }

  expectObject( keyValuePairs,
    "Missing or invalid parameter [keyValuePairs]");

  if( !exception.history )
  {
    exception.history = [];
  }

  const history = exception.history;

  const historyitem = {};

  let pushHistoryItem = false;

  for( let key in keyValuePairs )
  {
    if( undefined !== exception[ key ] )
    {
      historyitem[ key ] = exception[ key ];
      pushHistoryItem = true;
    }

    const newValue = keyValuePairs[ key ];

    if( newValue !== null && newValue !== undefined )
    {
      exception[ key ] = newValue;
    }
    else {
      delete exception[ key ];
    }
  } // end for

  if( pushHistoryItem )
  {
    history.push( historyitem );
  }

  return exception;
};
