import { equals } from "./Equals"
import { extendError } from "./Error"

const metaTypeCache =
  {
    'string': ['string'],
    'array': ['array'],
    'number': ['number'],
    'boolean': ['boolean'],
    'object': ['object'],
    'null': ['null'],

    'string|null': ['string', 'null'],
    'array|null': ['array', 'null'],
    'object|null': ['object', 'null']
  };

const EXT_LATIN_TOKENS_LC = "šœžÿñàáâãäåæçèéêëìíîïðòóôõöøùúûüýþß";
const REP_LETTER_LC = "a-z" + EXT_LATIN_TOKENS_LC;
const REP_LETTER_NUMBER_LC = "0-9a-z" + EXT_LATIN_TOKENS_LC;

const RE_EMAIL =
      new RegExp(
    '^(([^<>()[\\]\\.,;:\\s@"]+(.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((([' +
    '\\-' + REP_LETTER_NUMBER_LC +
    ']+\\.)+[' + REP_LETTER_LC + ']{2,}))$', 'i' );

// @see http://regexlib.com/Search.aspx?k=phone%20number
const RE_PHONE =
/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

const RE_POSTAL_CODE_DUTCH = /^[1-9]{1}[0-9]{3} ?[A-Za-z]{2}$/;

const RE_NAME =
  new RegExp( "^[" + REP_LETTER_LC + "\\s-]{2,}$", "i" );

const RE_FANTASY_NAME =
  new RegExp( "^[" + REP_LETTER_NUMBER_LC + "\\s-_&|,./\\\\]{2,}$", "i" );

const RE_ADDRESS =
  new RegExp( "^[" + REP_LETTER_NUMBER_LC + ".,°\\s-]{2,}$", "i" );

const RE_HAS_A_LETTER_OR_NUMBER =
  new RegExp( "[a-z0-9]+", "i" );

const RE_MULTIPLE_SPACES = /  +/g;

const is_nan = Number.isNaN;

// ------------------------------------------------------------------ hk.parse

/**
 * Parse a (single) value
 * - Ouputs the parsed result: validated and possibly processed by the
 *   specified parse filter
 *
 * @param {mixed} value - Value to parse
 * @param {object} parseInfo - Parse info to use to parse the value
 *
 * @param {object} options
 * @param {boolean} [options.ignoreRequired=false]
 *   Ignore missing properties; even if in parseInfo the required flag
 *   has been set (useful to check partial objects)
 *
 * @param {boolean} [options.useNullToDelete=false]
 *   If true; a value null means that the value should be deleted
 *   (the return value will be null)
 *
 * @return {mixed} parsed value
 */
export function parse( value, parseInfo, options={} )
{
  const { ignoreRequired=false } = options;

  let parseInfoType;
  let normalizedParseInfotype;

  // -- Step 1: parseInfo is 1 or true -> only ensure data property is set

    if( !(parseInfo instanceof Object) )
    {
      if( 1 === parseInfo || true === parseInfo )
      {
        // Everything is valid, also undefined
        return value;
      }

      throw new Error(
        "Invalid parameter [parseInfo] (expected object or 1 or true)");
    }

  // -- Apply useNullToDelete

  if( null === value && options.useNullToDelete )
  {
    if( parseInfo.required )
    {
      throw extendError(
        new Error("Value should not be [null] (required property)"),
        {
          reason: "undefined",
          value,
          parseInfo
        } );
    }

    return null;
  }

  // -- Shallow clone parseInfo

    parseInfo = Object.assign( {}, parseInfo );

  // -- Step 2: value is undefined or NaN -> set default or fail if required

    if( typeof value === "undefined" )
    {
      if( typeof parseInfo.default !== "undefined" )
      {
        return parseInfo.default;
      }
      else if( parseInfo.required && !ignoreRequired )
      {
        throw extendError(
          new Error("Value should not be [undefined] (required property)"),
          {
            reason: "undefined",
            value,
            parseInfo
          } );
      }
      else {
        // undefined is allowed..
        return undefined;
      }
    }
    else if( /* typeof value === "number" && */ is_nan( value ) )
    {
      //
      // @note Number.isNaN( "hello" ) -> false
      //
      throw extendError(
        new Error("Value should not be [NaN]"),
        {
          reason: "NaN",
          value,
          parseInfo
        } );
    }
    else if( typeof value === "string" &&
             !value.length && parseInfo.required )
    {
      //
      // @note Number.isNaN( "hello" ) -> false
      //
      throw extendError(
        new Error("Value should not be [empty] (required property)"),
        {
          reason: "Empty string",
          value,
          parseInfo
        } );
    }
    // else if( typeof value === "string" &&
    //          !value.length && parseInfo.emptyStringIsUndefined )
    else if( typeof value === "string" &&
             !value.length && !parseInfo.required )
    {
      // If not required, an empty string is also valid
      return value;
    }

  // -- Step 3: Check parseInfo.equals

    const equalsValue = parseInfo.equals;

    if( typeof equalsValue !== "undefined" )
    {
      switch( typeof equalsValue )
      {
        case 'string':
        case 'number':
        case 'boolean':
          if( value === equalsValue )
          {
            return value;
          }
          break;

        case 'object':
          if( null === value && null === equalsValue )
          {
            return value;
          }
          else if( equals(value, equalsValue ) )
          {
            return value;
          }
          break;

        default:
          break;
      } // end switch

      throw extendError(
        new Error( type_error_message( parseInfoType ) ),
        {
          reason: "invalidType",
          value,
          parseInfo
        } );

    } // end if parseInfo.equals

  // -- Step 4: check if value is valid using meta data

    parseInfoType = parseInfo.type;

    if( !parseInfoType )
    {
      throw new Error("Missing property [parseInfo.type]");
    }

    if( typeof parseInfoType === "string" )
    {
      //
      // Convert metaType to an array if a string is supplied
      //
      normalizedParseInfotype = metaTypeCache[parseInfoType];

      if( normalizedParseInfotype )
      {
        parseInfoType = normalizedParseInfotype;
      }
      else {
        normalizedParseInfotype = parseInfoType.split("|");

        // Add to cache
        metaTypeCache[ parseInfoType ] = normalizedParseInfotype;

        parseInfoType = normalizedParseInfotype;
      }
    }

    // @note parseInfoType is an array of allowed types from here ->

    let typeIsValid = false;

    for( let j = parseInfoType.length - 1; j >= 0; j = j - 1 )
    {
      let expectedType = parseInfoType[j];

      // -- Preprocess / checks

        if( typeof value === "string" )
        {
          switch( expectedType )
          {
            case 'email':
              parseInfo.trim = 1;
              parseInfo.singleSpaces = 1;
              parseInfo.toLowerCase = 1;
              break;

            case 'phone':
            case 'phone-number':
            case 'name':
            case 'address':
              parseInfo.trim = 1;
              parseInfo.singleSpaces = 1;
              break;

            case 'postal-code-dutch':
              parseInfo.trim = 1;
              parseInfo.singleSpaces = 1;
              parseInfo.toUpperCase = 1;
              break;

            default:
              break;
          }

          // Normalize unicode sequences
          value = value.normalize();

          if( parseInfo.singleSpaces )
          {
            value = single_spaces( value, parseInfo );
          }

          if( parseInfo.trim )
          {
            value = trim( value, parseInfo );
          }

          if( parseInfo.toLowerCase )
          {
            value = to_lower_case( value, parseInfo );
          }

          if( parseInfo.toUpperCase )
          {
            value = to_upper_case( value, parseInfo );
          }

          if( parseInfo.stripHtml )
          {
            value = strip_html( value, parseInfo );
          }
        }

      // -- Check type

        switch( expectedType )
        {
          case 'string':
            if( typeof value === expectedType )
            {
              typeIsValid = true;
            }
            break;

          case 'number':
            if( typeof value === "string" )
            {
              let tmp = parseFloat(value);

              if( !Number.isNaN(tmp) )
              {
                value = tmp;
                typeIsValid = true;
              }
            }

            if( typeof value === expectedType )
            {
              typeIsValid = true;
            }
            break;

          case 'boolean':
            if( typeof value === expectedType )
            {
              typeIsValid = true;
            }
            break;

          case 'array':
          case 'Array':
            if( Array.isArray(value) )
            {
              typeIsValid = true;
            }
            break;

          case 'object':
          case 'Object':
            if( value instanceof Object )
            {
              typeIsValid = true;
            }
            break;

          case 'null':
            if( null === value )
            {
              typeIsValid = true;
            }
            break;

          case '@item':
          case '@list':
            if( !is_multi_selector( value ) )
            {
              typeIsValid = parse_populate_selector( value );
            }
            else {
              // value is a multi selector (array of selectors)
              typeIsValid = true;

              for( let k = value.length - 1; k >= 0; k = k - 1 )
              {
                if( !parse_populate_selector( value[k] ) )
                {
                  typeIsValid = false;
                  break;
                }
              }
            }
            break;

          case 'email':
            if( typeof value === 'string' && RE_EMAIL.test(value) )
            {
              typeIsValid = true;
            }
            break;

          case 'phone':
          case 'phone-number':
            if( typeof value === 'string' && RE_PHONE.test(value) )
            {
              typeIsValid = true;
            }
            break;

          case 'name':
            if( typeof value === 'string' && value.length >= 2 &&
                RE_NAME.test(value) )
            {
              typeIsValid = true;
            }
            break;

          case 'fantasy-name':
            if( typeof value === 'string' && value.length >= 2 &&
                RE_FANTASY_NAME.test(value) &&
                RE_HAS_A_LETTER_OR_NUMBER.test( value ) )
            {
              typeIsValid = true;
            }
            break;

          case 'address':
            if( typeof value === 'string' && value.length >= 2 &&
                RE_ADDRESS.test(value) )
            {
              typeIsValid = true;
            }
            break;

          // case 'latin-text':
          //   if( typeof value === 'string' &&
          //       RE_LATIN_TEXT.test(value) )
          //   {
          //     typeIsValid = true;
          //   }
          //   break;

          case 'postal-code-dutch':
            if( typeof value === 'string' &&
                RE_POSTAL_CODE_DUTCH.test(value) )
            {
              typeIsValid = true;
            }
            break;

          default:
            console.log("Invalid parseInfo", parseInfo);

            throw new Error(
              `Invalid value [${expectedType}] in [parseInfo.type]`);
        } // end switch

        if( true === typeIsValid )
        {
          break;
        }
      } // end for

    // -- Postprocess / checks

      if( typeIsValid )
      {
        if( typeof value === "string" )
        {
          check_min_length( value, parseInfo );
          check_max_length( value, parseInfo );
        }
        else if( typeof value === "number" )
        {
          // check_value_range2( value, parseInfo );
        }
        else if( Array.isArray(value) )
        {
          check_min_length( value, parseInfo );
          check_max_length( value, parseInfo );
        }
      }

    // -- Generate exception if type if invalid

      if( false === typeIsValid )
      {
        throw extendError(
          new Error( type_error_message( parseInfoType ) ),
          {
            reason: "invalidType",
            value,
            parseInfo
          } );
      }

    return value;
};

// --------------------------------------------------------------- parseObject

/**
* Process a (parameters) object
*
* @param {Object} [object]
*   Data to parse, may be null or undefined
*
* @param {Object} objectParseInfo
*   Parse information to use to parse the supplied data.
*
*   The objectParseInfo object defines meta information for each property
*   in the data object that should be parsed.
*
*     @eg {
*           firstName: 1,
*           lastName: 1,
*           email: { type: 'email' },
*           age: {
*             type: "number",
*             required: true,
*             range: {
*               min: 1,
*               max: 100,
*               integer: true
*             }
*           }
*           otherProperty: <parseInfo>
*         } );
*
* @param {Object|1|true} objectParseInfo.x.parseInfo -
*   Meta information about the property to parse
*
* @param {string|Array} objectParseInfo.x.parseInfo.type -
*   Expected type or list of types
*
* @param {mixed} objectParseInfo.x.parseInfo.default -
*   Default value to set if the property is not supplied
*
* @param {number|boolean} objectParseInfo.x.parseInfo.required -
*   If set to 1 or true, the property is required
*
* @param {number} [objectParseInfo.x.parseInfo.minLength=0] -
*   Minimum length of the supplied string or array
*
* @param {number} [objectParseInfo.x.parseInfo.maxLength=infinity] -
*   Maximum length of the supplied string or array
*
* @param {object} [objectParseInfo.x.parseInfo.range] -
*   Range object, e.g. generated by hk.newRange() or a range object
*   description that can be converted to a HkRange object.
*
* ... TODO: more parsInfo options
*
* @param {object} options
*
* @param {boolean} [options.ignoreRequired=false]
*   Ignore missing properties; even if in parseInfo the required flag
*   has been set (useful to check partial objects)
*
* @param {boolean} [options.useNullToDelete=false]
*   If true; a value null means that the property should be deleted.
*   This is only valid if parseInfo.required has not been set to true.
*
* @param {boolean} [options.ignoreDefault=false]
*   Do not set default values for missing properties
*
* @throws ExtendedException
*        {
*          key: <property name that caused the exception>,
*          value: <value of the erroneous property>,
*          parseInfo: <parse info of the erroneous property>,
*          object: <input parameter object>,
*          objectParseInfo: <original parameter objectParseInfo>
*        } );
*
* @return {Object} processed object
*/
export function parseObject( object, objectParseInfo, options={} )
{
// -- Check input parameters

if( !(object instanceof Object) )
{
  throw new Error("Invalid parameter [object] (expected object)");
}

if( !objectParseInfo )
{
  return object;
}
else if( !(objectParseInfo instanceof Object) )
{
  throw new Error("Invalid parameter [parseInfo] (expected object)");
}

const {
  useNullToDelete = false,
  ignoreDefault = false } = options;

// -- Helper functions and variables

const parseInfoSet = new Set();

// @note out === object unless changed

let out = object;

function update_key( key, value )
{
  if( out === object )
  {
    out = Object.assign( {}, object );
  }
  out[ key ] = value;
}

function delete_key( key )
{
  if( out === object )
  {
    out = Object.assign( {}, object );
  }
  delete out[ key ];
}

// -- Process properties using "parseInfo"

// @note parseInfo and key are used in "catch"
let parseInfo;
let key;

try {
  for( key in objectParseInfo )
  {
    parseInfo = objectParseInfo[key];
    let value = object[key];

    if( ignoreDefault && value === undefined )
    {
      continue;
    }

    parseInfoSet.add( key );

    if( value === null )
    {
      if( useNullToDelete )
      {
        if( parseInfo.required )
        {
          throw extendError(
            new Error(
              `Value [null] is not allowed. (parameter [${key}] ` +
              `is required and [useNullToDelete=true] has been set)`),
            {
              key,
              value: object[key],
              object,
              objectParseInfo
            });
        }

        delete_key( key );
      }
      else {
        out[key] = null;
      }

      continue;
    }

    let parsedValue = parse( value, parseInfo, options );

    if( value !== parsedValue )
    {
      // @note assume hk.parse does not return null

      update_key( key, parsedValue );
    }

    // if( typeof parsedValue !== "undefined" )
    // {
    //   out[key] = parsedValue;
    // }

  } // end for
}
catch( e )
{
  throw extendError(
    e,
    { key, parseInfo, object, objectParseInfo }
  );
}

// -- Check for properties that are not defined in parseInfo

for( let key in object )
{
  if( !parseInfoSet.has( key ) )
  {
    throw extendError(
      new Error(`Unaccepted parameter [${key}]`),
      {
        key,
        value: object[key],
        object,
        objectParseInfo
      });
  }
} // end for

// -- Return parsed result

return out;
};


// ----------------------------------------------------------- Helper function

function trim( value, parseInfo )
{
  return value.trim();
}

// ----------------------------------------------------------- Helper function

function single_spaces( value, parseInfo )
{
  return value.replace( RE_MULTIPLE_SPACES, ' ' );
}

// ----------------------------------------------------------- Helper function

function to_lower_case( value, parseInfo )
{
  return value.toLowerCase();
}

// ----------------------------------------------------------- Helper function

function to_upper_case( value, parseInfo )
{
  return value.toUpperCase();
}

// ----------------------------------------------------------- Helper function

function strip_html( value, parseInfo )
{
  throw new Error("Not implemented yet");
}

// ----------------------------------------------------------- Helper function

// function latin(  value, parseInfo, exceptionHandler )
// {
//   throw new Error("Not implemented yet");
// }

// ----------------------------------------------------------- Helper function

function check_min_length( value, parseInfo )
{
  if( typeof parseInfo.minLength === "undefined" )
  {
    return;
  }

  // FIXME: string.length is not the number of unicode tokens

  if( value.length < parseInfo.minLength )
  {
    throw extendError(
      new Error(`Invalid length [minLength=${parseInfo.minLength}]`),
      {
        reason: "minLength",
        value,
        parseInfo
      } );
  }
} // end fn

// ----------------------------------------------------------- Helper function

function check_max_length( value, parseInfo )
{
  if( typeof parseInfo.maxLength === "undefined" )
  {
    return;
  }

  if( value.length > parseInfo.maxLength )
  {
    throw extendError(
      new Error(`Invalid length [maxLength=${parseInfo.maxLength}]`),
      {
        reason: "maxLength",
        value,
        parseInfo
      } );
  }
} // end fn

// ----------------------------------------------------------- Helper function

// function check_value_range2( value, parseInfo )
// {
//   let range = parseInfo.range;
//
//   if( typeof range === "undefined" )
//   {
//     return;
//   }
//   else if( !(range instanceof Object) )
//   {
//     throw new Error("Invalid parameter [range] (expected object or array)");
//   }
//
//   if( !range.isHkRange )
//   {
//     range = hk.newRange( range );
//   }
//
//   if( typeof range.isInRange === "function" )
//   {
//     if( false === range.isInRange( value ) )
//     {
//       throw extendError(
//         new Error(`Value out of range`),
//         {
//           reason: "outOfRange",
//           value,
//           parseInfo
//         } );
//     }
//   }
//
// } // end fn

// ----------------------------------------------------------- Helper function

function type_error_message( parseInfoType )
{
  let typeStr;

  if( Array.isArray( parseInfoType ) )
  {
    typeStr = ` (expected [${parseInfoType.join(",")}])`;
  }
  else if( typeof parseInfoType === "string" )
  {
    typeStr = ` (expected [${parseInfoType.join(",")}])`;
  }
  else {
    typeStr = "";
  }

  return "Invalid type" + typeStr;
}

// ----------------------------------------------------------- Helper function

const  reValidTablePath = new RegExp('^[a-z0-9_]+[.]{0,1}[a-z0-9_]*$');

function is_valid_table_path( tablePath )
{
  if( !tablePath ||
      typeof tablePath !== "string" ||
      false === reValidTablePath.test( tablePath ) )
  {
    return false;
  }

  return true;
}

// ----------------------------------------------------------- Helper function

function is_valid_selector_key( selectorKey )
{
  if( typeof selectorKey === "string" && selectorKey.length )
  {
    return true;
  }

  return false;
}

// ----------------------------------------------------------- Helper function

function is_multi_selector( value )
{
  if( Array.isArray( value ) && value.length )
  {
    for( let k = value.length - 1; k >= 0; k = k - 1 )
    {
      if( !Array.isArray( value[k] ) )
      {
        return false;
      }
    } // end for

    return true;
  }

  return false;
}

// ----------------------------------------------------------- Helper function

/**
 * Parse an "@item" populate selector
 *
 * @param {mixed} value - "@item" selector
 *
 * @returns {boolean} true is the selector is valid
 */
function parse_populate_selector( value )
{
  // -- Check simple cases

    if( (typeof value === "string" && value.length) ||
        value === null )
    {
      // DEPRECEATED (tablename only selector; wrap in array)
      return true;
    }

    if( !Array.isArray( value ) )
    {
      return false;
    }

    const n = value.length;

    if( n === 0 )
    {
      // Allow empty array (select nothing)
      return true;
    }

    if( n > 5 )
    {
      // [tableName, selectorKey, selectorValue, fields, options]
      return false;
    }

  // -- Check tableName

    if( !is_valid_table_path( value[0] ) )
    {
      return false;
    }

  // -- Check simple cases

    if( 1 === n )
    {
      // Only tableName was specified (done)
      return true;
    }

    if( 2 === n )
    {
      //
      // Assume self refering property:
      // e.g. [ "colors", "groupId=this._id" ]
      //
      const [ prop1, prop2 ] = value[1].split("=this.");

      if( prop1.length && prop2.length )
      {
        return true;
      }

      // Only tableName and selectorKey were specified
      // (missing selectorValue)
      return false;
    }

  // -- Check selectorKey

    if( !is_valid_selector_key( value[1] ) )
    {
      return false;
    }

  // -- Check selectorValue(s)

    const selectorValues = value[2];

    if( !(selectorValues instanceof Object) )
    {
      // selectorValues is not an Object -> is a primitive (valid)
      return true;
    }

    if( !Array.isArray( selectorValues ) )
    {
      return false;
    }

    // Expected list or primitives
    for( let k = selectorValues.length - 1; k >= 0; k = k - 1 )
    {
      if( selectorValues[k] instanceof Object )
      {
        return false;
      }
    } // end for

  // -- Check fields

    if( n <= 3 )
    {
      // No fields or options
      return true;
    }

    let selectorFields = value[3];

    if( !(selectorFields instanceof Object) )
    {
      // selectorFields should be an object
      return false;
    }

  // -- Check options

    if( n <= 4 )
    {
      // No options
      return true;
    }

    let selectorOptions = value[4];

    if( !(selectorOptions instanceof Object) )
    {
      // selectorOptions should be an object
      return false;
    }

  return true;

} // end fn
