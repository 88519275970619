import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { useDispatch } from "react-redux";
import { setValue } from "../../../store/formData/actions";

import "./Checkbox.scss";

export default function SignupFormCheckbox({
  formName,
  fieldName,
  widgetData,
}) {
  const dispatch = useDispatch();

  const { placeholder } = widgetData;

  function change(params) {
    const { checked } = params;

    dispatch(setValue({ formName, fieldName, value: checked }));
  }

  return (
    <div className="checkbox form-field">
      <CheckBoxComponent label={placeholder} change={change} />
    </div>
  );
}
