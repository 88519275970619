// React
import { useDispatch, useSelector } from "react-redux";

// Syncfusion
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';

// Store
import { setValue } from "../../../store/formData/actions";
import { getFieldValue } from "../../../store/formData/selectors";
import { fieldValid } from "../../../store/pages/selectors";

// Style
import "./DateRange.scss";

export default function DateRange({
  formName,
  fieldName,
  widgetData,
}) {
  const dispatch = useDispatch();

  const { placeholder } = widgetData;

  const value =
    useSelector( ( state ) => getFieldValue(state, fieldName));

  function change({ value }) {
    dispatch(setValue({ formName, fieldName, value }));
  }

  const { isValid, showValidation } =
    useSelector((state) => fieldValid( state, fieldName ));

  const minDate = new Date();

  return (
    <div className={ (!isValid && showValidation ) ? "form-field invalid" : "form-field" }>
      <DateRangePickerComponent
        min={minDate}
        minDays={7} maxDays={28}
        strictMode={true}
        label={placeholder}
        change={change}
        value= {value} />
    </div>
  );
}
