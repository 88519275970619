// React
import { useDispatch, useSelector } from "react-redux";

// Syncfusion
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

// Store
import { setValue } from "../../../store/formData/actions";
import { getFieldValue } from "../../../store/formData/selectors";
import { fieldValid } from "../../../store/pages/selectors";

// Style
import "./Dropdown.scss";

export default function Dropdown({
  formName,
  fieldName,
  widgetData,
}) {
  const dispatch = useDispatch();

  const { options, placeholder, label } = widgetData;

  const value =
    useSelector( ( state ) => getFieldValue(state, fieldName));

  const fields = {
    text: "placeholder",
    value: "value",
    groupBy: "groupBy"
  };

  function change({ value }) {
    dispatch(setValue({ formName, fieldName, value }));
  }

  const { isValid, showValidation } =
    useSelector((state) => fieldValid( state, fieldName ));

  return (
    <div className={ (!isValid && showValidation ) ? "dropdown form-field invalid" : "dropdown form-field" }>
      <DropDownListComponent
        fields={fields}
        dataSource={options}
        placeholder={placeholder}
        change={change}
        value= {value}
      />
    </div>
  );
}
