// React
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Store
import { getNextPagePath, getPreviousPagePath, getCurrentPage, getCurrentValidation }
  from "../../../store/pages/selectors";

import { setShowWidgetValidation }
  from "../../../store/pages/actions";


// Styles
import "./Navigation.scss";

/**
 * Navigation component
 */
export default function Navigation( { pages } ) {
  const dispatch = useDispatch();

  const nextPath = useSelector(getNextPagePath);
  const previousPath = useSelector(getPreviousPagePath);
  const { page, index } = useSelector(getCurrentPage);
  const validation = useSelector(getCurrentValidation);

  const pageValid = validation.isValid;

  const history = useHistory();

  /*  Navigate to page */
  function goToPage( path )
  {
    history.push( path );
  }

  function showValidation( path )
  {
    dispatch(setShowWidgetValidation( index, true ));
  }

  let previousButton = null;

  if( previousPath )
  {
    previousButton =
      (<div className="previous-page button"
            onClick={()=>goToPage(previousPath)}>
        <i class="far fa-angle-left"></i>
        <span>vorige</span>
      </div> );
  }

  return (
    <div className="navigation">
      <div className="wrapper">
        {previousButton}
        <div className={pageValid ? `next-page button` : `next-page disabled` }
             onClick={()=> pageValid ? goToPage(nextPath) : showValidation()}>
          {page.nextText}
          <i className="fal fa-angle-right" />
        </div>
      </div>
    </div>
  );
}
