// React
import "./Timeslots.scss";

export default function Timeslots({ sessionId, timeslots, pickTimeSlot })
{
  const options = [];

  for( const timeslot of timeslots )
  {
    const { plannedDate } = timeslot;

    let date =
      new Date(plannedDate).toLocaleDateString( "nl-NL",
      { weekday: "long",
        day: "numeric",
        month: "long" } );

    if( !date )
    {
      date = new Date(plannedDate).toDateString();
    }

    options.push(
      <li
        key={timeslot.id}
        className="button"
        onClick={(e) => pickTimeSlot(e, timeslot.id)} >
          <div className="icon">
            <i className="fal fa-calendar-day"></i>
          </div>

          <div className="moment">
            <div className="date">
              {date}
            </div>

            <div className="time-wrapper">
              <div>tussen</div>
              <div>{timeslot.etaRange.from.plannedDateTime}</div>
              <div>en</div>
              <div>{timeslot.etaRange.to.plannedDateTime}</div>
            </div>
          </div>

          {timeslot.greenChoice &&
            <div className="greenChoice">
              <i className="fas fa-leaf"></i>
            </div>}
      </li>
    );
  }

  return (
    <div className="timeslots form-field">
      <ul className={ timeslots && timeslots.length > 3 ? "grid" : "" }>
        {options}
      </ul>
    </div>
  );
}
