// App actions
import { PROJECT_INFO_SUCCESS }
  from "./actions";

const initialState = {};

/**
 * Project reducer
 * @param  {Object} [state=initialState] - Current state
 * @param  {String} type - Dispatch type
 * @param  {Object} payload
 *
 * @return {Object} - New state
 */
export default function project( state = initialState, { type, payload } ) {
  switch (type) {

    case PROJECT_INFO_SUCCESS: {
      const { projectInfo } = payload;
      return projectInfo;
    }

    default:
      return { ...state };
  }
}
