// Helpers
import { parse } from "./Parse";

export const validateWidgets = ( widgets, values ) => {

  let isValid = true;
  const invalidFieldNames = [];

  for( const widget of widgets )
  {
    const { fieldName, parseInfo } = widget;
    const value = values[fieldName];

    try{
      parse( value, parseInfo );
    }
    catch( error )
    {
      isValid = false;
      invalidFieldNames.push( widget.fieldName );
    }
  }

  return { isValid, invalidFieldNames };
}
