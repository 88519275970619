// -------------------------------------------------------------------- Selector

/**
 * Get suggestion
 *
 * @param  {Object} state - Current state
 *
 * @return {Object} - Form object
 */
export const getSuggestion = ( state ) => {

  const suggestion = { ...state.suggestion };

  return suggestion;
};
