// Helpers
import { objectGet } from "../helpers/Objects";
import { expectObject, expectString } from "../helpers/Expect";
import { getBackendHost } from "../config/Config";

const backendHost = getBackendHost();
// -------------------------------------------------------------------- Function

// -------------------------------------------------------------------- Function

export const getProjectInfo = async () => {

  const path = `/api/website/info/get`;
  const url = `${backendHost}${path}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    const result = await response.json();
    return result.projectInfo;
  } catch (err) {
    console.log(err);
  }
};

// -------------------------------------------------------------------- Function

export const getPages = async () => {

  const path = `/api/website/pages/get`;
  const url = `${backendHost}${path}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    const result = await response.json();

    return result.pages;
  } catch (err) {
    console.log(err);
  }
};
