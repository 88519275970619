// App actions
import { appLoading, appDoneLoading } from "../appState/actions";

// Services
import { getProjectInfo } from "../../services/Project";

// Dispatch types
export const PROJECT_INFO_SUCCESS = "PROJECT_INFO_SUCCESS";

// -------------------------------------------------------------------- Function

/**
 * Fetch pages from Bessy backend
 *
 * @param  {String} id - Id of the form
 */
export const fetchProjectInfo = () => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    try {
      const projectInfo = await getProjectInfo();

      await dispatch({
        type: PROJECT_INFO_SUCCESS,
        payload: { projectInfo }
      });

      dispatch(appDoneLoading());

    } catch (err) {
      console.log(err.message);

      dispatch(appDoneLoading());
    }
  };
};
