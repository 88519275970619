// ----------------------------------------------------- equals implementation

/**
 * Check if the values of two variables should be considered the same
 *
 * @param  {mixed} value1 - First value for comparison
 * @param  {mixed} value2 - Second value for comparison
 *
 * @return {boolean} true if the two values can be considered the same
 */
 export function equals( value1, value2, _pendingComparisons )
 {
   // -------------------------------------------- Basic sameness comparisons

     if( typeof value1 !== typeof value2 )
     {
       // Not the same [type]
       return false;
     }

     if( !(value1 instanceof Object) && value1 !== value2 )
     {
       // [Not an object] and [not the same value]
       return false;
     }

     if( value1 === value2 )
     {
       // [same value or same object reference]
       return true;
     }

     if( value1 === null || value2 === null )
     {
       // [one of the values is null, the other an object]
       return false;
     }

   // ------------------------- Check if variables are already being compared

     var foundIndex;
     var values1;
     var values2;

     if( !_pendingComparisons )
     {
       _pendingComparisons = { value1: [], value2: [] };
     }
     else {
       values1 = _pendingComparisons.value1;
       values2 = _pendingComparisons.value2;

       if( values1.length > 0 )
       {
         foundIndex = values1.indexOf(value1);

         if( -1 !== foundIndex && values2[foundIndex] === value2 )
         {
           // Objects comparison in progress (it's safe to return true),
           // since equals will fail on other place if objects differ
           return true;
         }
       }
     }

   // ----------------------------------------------- Compare special objects

     var Constructor = value1.constructor;

     switch (Constructor)
     {
       // Implement other special objects here.

       case RegExp:
         if( value1.toString() === value2.toString() )
         {
           return true;
         }
         return false;

       case Date:
         if( value1.getTime() === value2.getTime() )
         {
           return true;
         }
         return false;

      default:
        // nothing todo
     }

   // ------------------------ Check if both objects have the same properties

     var prop;

     for( prop in value1 )
     {
       if( typeof value2[prop] === "undefined" &&
           typeof value1[prop] !== "undefined" )
       {
         // (defined) property found in value1 that is undefined in value2
         return false;
       }
     }

     for( prop in value2 )
     {
       if( typeof value1[prop] === "undefined" &&
           typeof value2[prop] !== "undefined" )
       {
         // (defined) property found in value2 that is undefined in value1
         return false;
       }
     }


   // -------------------------------------- Check each property for sameness

     _pendingComparisons.value1.push(value1);
     _pendingComparisons.value2.push(value2);

     for( prop in value1 )
     {
       if( false === equals(
           value1[prop], value2[prop], _pendingComparisons ) )
       {
         return false;
       }
     }

   return true;
 }
