// Images
import LoaderClip from "../../../img/loader-clip.svg";
import LoaderGradient from "../../../img/loader-gradient.svg";

// Style
import "./Loader.scss";

export default function Loader({ Image, loading }) {
  return (
    <div className={loading ? `loader loading` : `loader`}>
      <img src={LoaderGradient} alt="" className="loader-gradient" />
      <img src={LoaderClip} alt="" className="loader-clip" />
      <div className="image-container">
        {Image}
      </div>
    </div>
  );
}
