import { expectObject, expectString } from "../../helpers/Expect";
import { appLoading, appDoneLoading } from "../appState/actions";

export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const SET_VALUE_SUCCESS = "SET_VALUE_SUCCESS";

// -------------------------------------------------------------------- Function

export const setValue = ({ fieldName, value }) =>
{

  return async (dispatch, getState) => {
    dispatch(appLoading());

    dispatch({
      type: SET_VALUE_SUCCESS,
      payload: { fieldName, value },
    });

    dispatch(appDoneLoading());
  };
};
