// Helpers
import { objectGet } from "../helpers/Objects";
import { expectObject, expectString } from "../helpers/Expect";
import { getBackendHost } from "../config/Config";

const backendHost = getBackendHost();

// -------------------------------------------------------------------- Function

export const createTask = async (data) => {
  // Validate data
  expectObject(data, "Invalid [data]");

  const { client, location, task } = data;

  expectObject(client, "Invalid [client]");

  expectString( client.lastName, "Invalid [lastName]" );
  expectString( client.emailAddress, "Invalid [lastName]" );

  expectObject(location, "Invalid [location]");
  expectString( location.street, "Invalid [street]" );
  expectString( location.streetNumber, "Invalid [streetNumber]" );
  expectString( location.postalCode, "Invalid [postalCode]" );
  expectString( location.place, "Invalid [placeName]" );
  expectString( location.gpsLatitude, "Invalid [gpsLatitude]" );
  expectString( location.gpsLongitude, "Invalid [gpsLongitude]" );

  expectObject(task, "Invalid [task]");

  const path = `/api/website/task/create`;
  const url = `${backendHost}${path}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response.json();
  } catch (err) {
    console.log(err);
  }
};

// -------------------------------------------------------------------- Function

export const getTaskPropsWidgets = async () => {
  const path = `/api/website/widgets/task-props/get`;
  const url = `${backendHost}${path}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    const { widgets } = await response.json();

    return widgets;

  } catch (err) {
    console.log(err);
  }
};
