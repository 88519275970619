import { combineReducers } from "redux";
import appState from "./appState/reducer";
import pages from "./pages/reducer";
import project from "./project/reducer";
import formData from "./formData/reducer";
import suggestion from "./suggestion/reducer";

export default combineReducers({
  appState,
  pages,
  project,
  formData,
  suggestion
});
