import { expectObject, expectArray } from "./Expect"
import { equals } from "./Equals"

// -------------------------------------------------------------------- Function

/**
 * Convert string with dot separated values to a list of values
 * - Accepts that the supplied path is already an array path
 *
 * @param {string|string[]} path
 *
 * @returns {string[]} list of path values
 */
export function ensureArrayPath( path )
{
  if( typeof path === "string" )
  {
    return path.split(".");
  }
  else if( Array.isArray( path ) )
  {
    // Nothing to do
    return path;
  }
  else {
    throw new Error(
      "Missing or invalid parameter [path] (expected string or array)");
  }
};

// -------------------------------------------------------------------- Function

/**
 * Set a value in an object using a path and value pair.
 * - Automatically creates parent objects
 *
 * @param {object} obj - Object to set the value in
 * @param {string|Array} path - Dot separated string path or array path
 * @param {mixed} value - value to set
 */
export function objectSetPath( targetObject, path, sourceObject )
{
  expectObject( targetObject,
    "Missing or invalid parameter [targetObject]" );

  const arrPath = ensureArrayPath( path );

  expectObject( sourceObject,
    "Missing or invalid parameter [sourceObject]" );

  const value = objectGet( sourceObject, arrPath );

  // hk.debug(
  //   "objectSetPath",
  //   {
  //     targetObject, arrPath, sourceObject, value
  //   } );

  if( value === undefined )
  {
    //
    // value to set is undefined -> delete value from target object
    //
    const parentNode = get_parent( targetObject, arrPath );
    const lastKey = arrPath[ arrPath.length - 1 ];

    if( Array.isArray(parentNode) )
    {
      const keyAsInt = parseInt( lastKey, 10 );

      if( Number.isNaN( keyAsInt ) || keyAsInt < 0 )
      {
        throw new Error(
          "Cannot delete property [" + arrPath.join(".") + "] " +
          "from data node of type [Array]");
      }

      parentNode.splice( keyAsInt, 1 );
    }
    else if( parentNode )
    {
      delete parentNode[ lastKey ];
    }
    // else no parent node -> nothing to do

    return;
  }

  // @note value to set is not undefined >>

  // -- Create parent nodes
  let currentTarget = targetObject;
  let currentSource = sourceObject;

  let n_1 = arrPath.length - 1;

  for( let j = 0; j < n_1; j = j + 1 )
  {
    let key = arrPath[j];

    // hk.debug( { key, currentTarget, currentSource } );

    // @note current is always an object

    if( !(key in currentTarget) )
    {
      // Parent does not exist
      // -> create new parent object
      // -> recurse into new parent

      if( !Array.isArray( currentSource[key] ) )
      {
        currentTarget = currentTarget[key] = {};
      }
      else {
        currentTarget = currentTarget[key] = [];
      }

      currentSource = currentSource[key];
      continue;
    }

    let nextTarget = currentTarget[ key ];
    let nextSource = currentSource[ key ];

    if(  Array.isArray( nextTarget ) &&
        !Array.isArray( nextSource ) )
    {
      // Target is an array, but source not
      // -> convert to normal object
      nextTarget = currentTarget[ key ] = {};
    }
    else if( !Array.isArray( nextTarget ) &&
         Array.isArray( nextSource ) )
    {
      // Target is not an array, but source is
      // -> convert to array
      nextTarget = currentTarget[ key ] = [];
    }
    else if( nextTarget instanceof Object )
    {
      currentTarget = nextTarget;
    }
    else {
      // nextTarget is not an object
      // -> convert to object or array

      if( Array.isArray( nextSource ) )
      {
        currentTarget = currentTarget[ key ] = [];
      }
      else {
       currentTarget = currentTarget[ key ] = {};
      }
    } // end else

    currentSource = nextSource;

  } // end for

  // -- Set value
  {
    const lastKey = arrPath[ n_1 ];

    // hk.debug( { lastKey, currentTarget, currentSource } );

    currentTarget[ lastKey ] = currentSource[ lastKey ];
  }
};

// -------------------------------------------------------------------- Function

/**
 * Set a value in an object using a path and value pair.
 * - Automatically creates parent objects
 *
 * @param {object} obj - Object to set the value in
 * @param {string|Array} path - Dot separated string path or array path
 * @param {mixed} value - value to set
 */
export function objectSet( obj, path, value )
{
  // hk.debug( "JENS: objectSet", { obj, path, value } );

  expectObject( obj, "Missing or invalid parameter [obj]" );

  const arrPath = ensureArrayPath( path );

  if( arguments.length < 3 )
  {
    throw new Error("Missing or invalid parameter [value]");
  }

  let parentNode;
  const lastKey = arrPath[ arrPath.length - 1 ];

  if( value !== undefined )
  {
    parentNode = ensure_parent( obj, arrPath );
  }
  else {
    //
    // value to set is undefined -> delete value from target object
    //
    parentNode = get_parent( obj, arrPath );

    if( Array.isArray(parentNode) )
    {
      const keyAsInt = parseInt( lastKey, 10 );

      if( Number.isNaN( keyAsInt ) || keyAsInt < 0 )
      {
        throw new Error(
          "Cannot delete property [" + arrPath.join(".") + "] " +
          "from data node of type [Array]");
      }

      parentNode.splice( keyAsInt, 1 );
    }
    else if( parentNode )
    {
      delete parentNode[ lastKey ];
    }
    // else no parent node -> nothing to do

    return;
  }

  // -- Set value

  const existingValue = parentNode[ lastKey ];

  if( !equals( value, existingValue ) )
  {
    parentNode[ lastKey ] = value;
  }

}; // end fn hk.objectSet

// -------------------------------------------------------------------- Function

/**
 * Removes a value at the specified object path from the object.
 * - All parent objects that remain empty will be removed too (recursively)
 *
 * @param {object} obj - Object to set the value in
 * @param {string|Array} path - Dot separated string path or array path
 * @param {mixed} value - value to set
 */
export function deleteObjectPath( obj, path )
{
  if( !(obj instanceof Object) )
  {
    throw new Error("Missing or invalid parameter [obj] (expected object)");
  }

  const arrPath = ensureArrayPath( path );

  const n = arrPath.length;
  const n_1 = n - 1;

  if( !n )
  {
    // Path is empty ""
    return;
  }

  const lastKey = arrPath[ n_1 ];

  if( 1 === n )
  {
    // Path consist of a single key
    delete obj[ lastKey ];
    return;
  }

  // path is longer than a single key >>

  // -- Get parent objects

  const parents = [];

  let current = obj;

  let endValueFound = true;

  for( let j = 0; j < n; j = j + 1 )
  {
    if( !(current instanceof Object) )
    {
      break;
    }

    parents.push( current );

    const key = arrPath[j];

    // hk.debug(
    //   {
    //     current,
    //     key,
    //     next: current[ key ]
    //   } );

    if( !(key in current) )
    {
      // child not found -> no more parents
      endValueFound = false;
      break;
    }

    current = current[ key ];
  }

  // hk.debug( "parents", parents );

  // -- Delete value from direct parent

  const n_parents = parents.length - 1;

  if( endValueFound )
  {
    const lastParent = parents[ n_parents ];

    if( !Array.isArray(lastParent) )
    {
      delete lastParent[ lastKey ];
    }
    else {
      lastParent.splice( parseInt( lastKey, 10 ), 1 );
    }
  }

  // -- Remove empty parents

  for( let j = n_parents - 1; j >= 0; j = j - 1 )
  {
    const parent = parents[ j ];
    const key = arrPath[j];
    const child = parent[ key ];

    let childIsEmpty = false;

    if( Array.isArray( child ) )
    {
      // Child is array
      if( 0 === child.length )
      {
        childIsEmpty = true;
      }
    }
    else {
      // Child is object
      if( 0 === Object.keys( child ).length )
      {
        childIsEmpty = true;
      }
    }

    if( !childIsEmpty )
    {
      // done
      break;
    }

    // Remove empty child from parent

    if( !Array.isArray( parent ) )
    {
      delete parent[ key ];
      break;
    }
    else {
      parent.splice( parseInt( key, 10 ), 1 );
    }

  } // end for
};

// -------------------------------------------------------------------- Function

/**
 * Get a value from an object using a path
 * - Can return a default value if no value was found
 *
 * @param {object} obj - Object to get the value from
 * @param {string|Array} path - Dot separated string path or array path
 *
 * @param {mixed} [defaultValue=undefined]
 *   Value to return if the value does not exist
 *
 * @return {mixed} value found at path, defaultValue or undefined
 */
export function objectGet( obj, path, defaultValue )
{
  if( !(obj instanceof Object) )
  {
    throw new Error("Missing or invalid parameter [obj] (expected object)");
  }

  const arrPath = ensureArrayPath( path );

  const parentNode = get_parent( obj, arrPath );

  if( !parentNode )
  {
    return defaultValue; // @note may be undefined
  }

  const lastKey = arrPath[ arrPath.length - 1 ];

  const value = parentNode[ lastKey ];

  if( typeof value === "undefined" )
  {
    return defaultValue;  // @note may be undefined
  }

  return value;

}; // end fn hk.objectGet

// -------------------------------------------------------------------- Function

/**
* Get an iterator that returns the value of a path for each item in the list
*
* @param {object[]} arr - Array of objects
* @param {string|string[]} path - Dot separated string path or array path
*
* @param {object} [options] - options
*
* DEPRECEATED >>> NOT COMPATIBLE WITH LIGHTWEIGHT ITERATOR
* @param {object} [options.unique=false] - Only return unique values
*
* @param {mixed} [options.defaultValue]
*   Value to return if the value does not exist
*
* @returns {Iterator<mixed>} value at the specified path for each item
*/
export function objectValues( arr, path, options )
{
  if( !Array.isArray(arr) )
  {
    throw new Error("Missing or invalid parameter [arr] (expected Array)");
  }

  if( typeof path !== "string" && !Array.isArray(path) )
  {
    throw new Error(
      "Missing or invalid parameter [path] (expected string or Array");
  }

  options = Object.assign(
    {
      unique: false,
      defaultValue: undefined
    },
    options );

  if( options.unique )
  {
    // Keep track of all values to prevent duplicates
  }

  throw new Error("NOT IMPLEMENTED YET");

}; // end fn objectValues

// ----------------------------------------------------------- Helper function

/**
 * Convert an array path to a dot separated path
 *
 * @param {string[]} arr
 * @param {number} [lastIndex]
 *   Last element of the array path to join, starting at 0
 *
 * @returns {string} dot separated path
 */
function join_path( arrPath, lastIndex )
{
  expectArray( arrPath, "Missing or invalid parameter [arrPath]" );

  if( lastIndex === undefined )
  {
    // No lastIndex -> join all elements
    return arrPath.join(".");
  }
  else if( 0 !== lastIndex )
  {
    // lastIndex is not 0 -> join elements [0..lastIndex]
    return arrPath.slice( 0, lastIndex ).join(".");
  }

  // lastIndex = 0 -> return empty path
  return "";
}

// ----------------------------------------------------------- Helper function

/**
 * Create all parent objects on the object path if they do not yet exist yet
 * - This method will throw an exception if there is a non-object node in
 *   the path
 *
 * @param {object} obj
 *   Object to create the parent objects in
 *
 * @param {string[]} arrPath
 *   The path that specified which parent oobjects to create
 *
 * @returns {object} the input object with the created object properties
 */
function ensure_parent( obj, arrPath )
{
  expectObject( obj, "Missing or invalid parameter [obj]" );
  expectArray( arrPath, "Missing or invalid parameter [arrPath]" );

  let current = obj;

  for( let j = 0, n_1 = arrPath.length - 1; j < n_1; j = j + 1 )
  {
    let key = arrPath[j];

    if( !(key in current) )
    {
      // Parent does not exist
      // -> create new parent object
      // -> recurse into new parent

      current = current[key] = {};
      continue;
    }

    if( Array.isArray(current) )
    {
      const keyAsInt = parseInt( key, 10 );

      if( Number.isNaN( keyAsInt ) || keyAsInt < 0 )
      {
        throw new Error(
          "Invalid parent [" + join_path(arrPath, j) + "] " +
          "Data node is an [Array], so [key] should be a positive integer");
      }

      // Current is an array and keyAsInteger is a number

      if( !(current[ key ] instanceof Object) )
      {
        // Child is not an object or array
        // -> overwrite with new parent object
        // -> recurse into new parent

        current = current[ keyAsInt ] = {};
        continue;
      }

      // Child is an object
      // -> recursive into next parent

      current = current[ keyAsInt ];
      continue;
    }

    // Current is an object
    // -> recurse into next parent

    if( !(current[ key ] instanceof Object) )
    {
      // Child is not an object or array
      // -> overwrite with new parent object
      // -> recurse into new parent

      current = current[key] = {};
      continue;
    }

    // Child is an object
    // -> recursive into next parent

    current = current[ key ];

  } // end for

  return current;
}

// ----------------------------------------------------------- Helper function

/**
 * Get parent object at the specified path
 *
 * @param {object}   obj - Object to work in
 * @param {string[]} arrPath - Path to get the parent object for
 *
 * @returns {object|array|null} parent object or null if not found
 */
function get_parent( obj, arrPath )
{
  expectObject( obj, "Missing or invalid parameter [obj]" );
  expectArray( arrPath, "Missing or invalid parameter [arrPath]" );

  let current = obj;

  for( let j = 0, n_1 = arrPath.length - 1; j < n_1; j = j + 1 )
  {
    let key = arrPath[j];

    if( !(key in current) )
    {
      return null;
    }

    current = current[key];

    if( current instanceof Object )
    {
      if( Array.isArray(current) && j < n_1 - 1 )
      {
        // node is an array
        // -> use next part of the array path as (numerical) array index

        key = arrPath[ j + 1 ];
        const keyAsInt = parseInt( key, 10 );

        if( Number.isNaN( keyAsInt ) || keyAsInt < 0 )
        {
          throw new Error(
            "Cannot get property [" + join_path(arrPath, j) + "] " +
            "from data node of type [Array]");
        }
      }
    }
    else {
      // not-object property -> "not found"
      return null;
    }

  } // end for

  // hk.debug( { current } );

  return current;
}
