// React
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapTilesUrl } from "../../config/Config";

// Services
import FormBuilder from "../../helpers/FormBuilder";
import {
  getSearchSuggestions,
  mapboxFeatureToAddress,
} from "../../services/LocationData";

// Stores
import { setValue } from "../../store/formData/actions";
import { getFieldValue, getAllFieldValues } from "../../store/formData/selectors";
import { setPageValidation } from "../../store/pages/actions";
import { getCurrentPage } from "../../store/pages/selectors";

// Syncfusion
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

// Helpers
import { objectGet } from "../../helpers/Objects";
import { expectNumber } from "../../helpers/Expect";
import { validateWidgets } from "../../helpers/ValidateWidgets";

// Mapbox
import mapboxgl from "mapbox-gl";

// Widgets
import Progress from "../../components/widgets/progress/Progress";
import Navigation from "../../components/widgets/navigation/Navigation";

// Styles
import "./LocationPage.scss";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// -------------------------------------------------------------------- Function

/**
 * LocationPage component
 */
export default function LocationPage() {
  const { page, index } = useSelector(getCurrentPage);

  const { widgets } = page;

  mapboxgl.accessToken =
    "sk.eyJ1IjoiYmVzc3ktc29sdXRpb25zIiwiYSI6ImNrbHo0bGtlaTFlajUyb213OHFnbjVyOWUifQ.9YYEZaXK0RQ-UMlgY7f72g";

    const values = useSelector(getAllFieldValues);

    useEffect(() => {
      const { isValid, invalidFieldNames } =
        validateWidgets( widgets, values );

      dispatch(setPageValidation( index, { isValid, invalidFieldNames } ));
    }, [ values ]);

  const dispatch = useDispatch();

  const street = useSelector((state) => getFieldValue(state, "street"));
  const postalCode = useSelector((state) => getFieldValue(state, "postalCode"));
  const place = useSelector((state) => getFieldValue(state, "place"));

  const gpsLatitude =
    useSelector((state) => getFieldValue(state, "gpsLatitude")) || 52.4;

  const gpsLongitude =
    useSelector((state) => getFieldValue(state, "gpsLongitude")) || 5.3;

  const [zoom, setZoom ] = useState(7);

  const mapContainer = useRef();

  const [lng, setLng] = useState( gpsLongitude);
  const [lat, setLat] = useState( gpsLatitude );

  const [searchText, setSearchText] = useState();
  const [suggestions, setSuggestions] = useState();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapTilesUrl,
      center: [lng, lat],
      zoom: zoom,
      interactive: false,
    });
    return () => map.remove();
  }, [lng, lat, zoom]);

  let formElement;

  if (street || postalCode || place ) {
    if( zoom !== 15 )
    {
      setZoom(15);
    }

    formElement = (
      <div className="form">
        <FormBuilder widgets={widgets} />
      </div>
    );
  } else {
    formElement = (
      <div className="form form-search">
        <div className="text-box form-field">
          <TextBoxComponent
            placeholder="Straat + huisnummer"
            type="text"
            value={searchText}
            input={get_suggestions}
          />
          {suggestions}
        </div>
      </div>
    );
  }

  return (
    <div className="location-page">
      <div className="map-container" ref={mapContainer} />

      <div className="content">
        <Progress />

        {formElement}
      </div>

      <Navigation />
    </div>
  );

  // ------------------------------------------------------------------ Function

  /**
   * Get suggestions
   *
   * @param  {String} text - Search string
   *
   * @return {Array} - Array with suggestion objects
   */
  async function get_suggestions({ value: text }) {
    const suggestions = [];

    if (!text || text.length < 2) {
      return [];
    }

    let features;

    try {
      const result = await getSearchSuggestions(text);

      if (!result.features || !result.features.length) {
        return;
      }

      features = result.features;
    } catch (error) {
      console.log("Failed to get search suggestions", error);
      return;
    }

    for (const feature of features) {
      suggestions.push(
        <div
          className="suggestion"
          key={feature.id}
          onClick={() => select_suggestion(feature)}
        >
          {feature.place_name_nl}
        </div>
      );
    }

    setSuggestions(<div className="suggestions">{suggestions}</div>);
  }

  // ------------------------------------------------------------------ Function

  /**
   * Select a suggestion
   *
   * @param  {Object} feature - Mapbox feature
   */
  function select_suggestion(feature) {
    objectGet(feature, "Invalid [feature]");

    const location = mapboxFeatureToAddress(feature);

    if (location.place) {
      dispatch(setValue({ fieldName: "place", value: location.place }));
    }

    if (location.street) {
      dispatch(setValue({ fieldName: "street", value: location.street }));
    }

    if (location.streetNumber) {
      dispatch(
        setValue({ fieldName: "streetNumber", value: location.streetNumber })
      );
    }

    if (location.postalCode) {
      dispatch(
        setValue({ fieldName: "postalCode", value: location.postalCode })
      );
    }

    if (location.country) {
      dispatch(
        setValue({ fieldName: "country", value: location.country })
      );
    }

    if (location.gpsLatitude) {
      dispatch(
        setValue({ fieldName: "gpsLatitude", value: location.gpsLatitude }),
        setLat( location.gpsLatitude ),
        setZoom(15)
      );
    }

    if (location.gpsLongitude) {
      dispatch(
        setValue({ fieldName: "gpsLongitude", value: location.gpsLongitude }),
        setLng( location.gpsLongitude ),
        setZoom(15)
      );
    }

    // TODO: Set location on map
    // {
    //   place: feature.place_name_nl,
    //   text: feature.text_nl,
    //   center: feature.center,
    //   feature
    // }
  }
}
