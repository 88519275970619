// App actions
import { FETCH_PAGES_SUCCESS, SET_ACTIVE_SUCCESS, SET_PAGE_VALIDATION_SUCCESS, SHOW_WIDGET_VALIDATION_SUCCESS }
  from "./actions";

const initialState = [];

/**
 * Fetch pages reducer
 * @param  {Object} [state=initialState] - Current state
 * @param  {String} type - Dispatch type
 * @param  {Object} payload
 *
 * @return {Object} - New state
 */
export default function pages(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PAGES_SUCCESS: {
      const { pages, currentPath } = payload;

      for( const page of pages )
      {
        if( page.path === currentPath )
        {
          page.isCurrentPage = true;
        }
        else{
          page.isCurrentPage = false;
        }
      }

      return pages;
    }

    case SET_ACTIVE_SUCCESS: {
      const { currentPath } = payload;

        const pages = [ ...state ];

        for( const page of pages )
        {
          if( page.path === currentPath )
          {
            page.isCurrentPage = true;
          }
          else{
            page.isCurrentPage = false;
          }
        }

      return pages;
    }

    case SET_PAGE_VALIDATION_SUCCESS: {
      const { index, validation } = payload;

        const pages = [ ...state ];

        if( pages[index] )
        {
          const _validation = pages[index].validation;

          pages[index].validation =
            Object.assign( { ..._validation }, validation );
        }

      return pages;
    }

    case SHOW_WIDGET_VALIDATION_SUCCESS: {
      const { index, showValidation } = payload;

      const pages = [ ...state ];

      pages[index].validation.showValidation = showValidation;

      return pages;
    }

    default:
      return [...state];
  }
}
