// -------------------------------------------------------------------- Selector

// -------------------------------------------------------------------- Function

/**
 * Get project info
 *
 * @return {Object} { index, page }
 */
export const getProjectInfo = ( state ) =>
{
  const projectInfo = { ...state.project };

  return { projectInfo };
}
