// Widgets
import Progress from "../../components/widgets/progress/Progress";

// Styles
import "./ConfirmPage.scss";
import Message from "../../components/widgets/message/Message";

// -------------------------------------------------------------------- Function

/**
 * TaskPage component
 */
export default function ConfirmPage() {
  return (
    <div className="task-page">
      <div className="content">
        <Progress />
        <div className="form">
          <Message />
        </div>
      </div>

      {/* <Navigation pages={pages} /> */}
    </div>
  );
}
