// Widgets
import Checkbox from "../components/widgets/checkbox/Checkbox";
import Dropdown from "../components/widgets/dropdown/Dropdown";
import MultiDropdown from "../components/widgets/multiDropdown/MultiDropdown";
import Textbox from "../components/widgets/textbox/Textbox";
import DateRange from "../components/widgets/dateRange/DateRange";
import DatePicker from "../components/widgets/datePicker/DatePicker";


export default function FormBuilder({ widgets }) {
  const widgetElements = [];

  if( !widgets )
  {
    return null;
  }

  for (const widget of widgets) {
    const { widgetData, widgetType, fieldName, parseInfo } = widget;

    switch (widgetType) {
      case "dropdown":
        widgetElements.push(
          <Dropdown
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );
        break;

      case "multiDropdown":
        widgetElements.push(
          <MultiDropdown
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );

        break;

      case "check":
        widgetElements.push(
          <Checkbox
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );

        break;

      case "input":
        widgetElements.push(
          <Textbox
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );

        break;

      case "dateRange":
        widgetElements.push(
          <DateRange
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );

        break;

      case "datePicker":
        widgetElements.push(
          <DatePicker
            fieldName={fieldName}
            widgetData={widgetData}
            key={fieldName}
            parseInfo={parseInfo}
          />
        );

        break;

      default:
        console.log(`Unkown widgetType [${widgetType}]`);
    }
  }

  return widgetElements;
}
