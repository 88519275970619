// Helpers
import { objectGet } from "../helpers/Objects";
import { expectObject, expectString } from "../helpers/Expect";
import { getBackendHost } from "../config/Config";

const backendHost = getBackendHost();

// -------------------------------------------------------------------- Function

export const createSession = async ( { accessCode } ) => {
  expectString( accessCode, "Invalid [accessCode]" );

  const path = `/api/website/session/create`;
  let params = `?uac=${accessCode}`;

  const url = `${backendHost}${path}${params}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    return response.json();
  } catch (err) {
    console.log(err);
  }
};

// -------------------------------------------------------------------- Function

export const sendAuthenticationMail = async ( { userId, taskId } ) => {
  expectString( userId, "Invalid [userId]" );
  expectString( taskId, "Invalid [taskId]" );

  const path = `/api/website/session/send-mail`;
  let params = `?userId=${userId}&taskId=${taskId}`;

  const url = `${backendHost}${path}${params}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    return response.json();
  } catch (err) {
    console.log(err);
  }
};
