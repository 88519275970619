// React
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Stores
import { getCurrentPage } from "../../../store/pages/selectors";

// Widgets
import Loader from "../loader/Loader";

// Images
import mailSend from "../../../img/mail-send.svg";
import generateSuggestion from "../../../img/generate-suggestion.svg";

// Styles
import "./Message.scss";

// Helpers
import { objectGet } from "../../../helpers/Objects";
import { getBackendHost } from "../../../config/Config";

/**
 * ConfirmEmailPage component
 */
export default function Message( { messageLabel }) {

  const { page } = useSelector(getCurrentPage);

  const [Image, setImage] = useState();

  const [Message, setMessage] = useState();

  const {
    messages
  } = page;

  useEffect(() => {
    let message;

    for( const _message of messages )
    {
      if( _message.label === messageLabel )
      {
        message = _message;
      }
    }

    if( !message )
    {
      return;
    }

    // Set message
    const messageComponent = (
      <div className="message-content">
        { message.title ? <div className="title">{message.title}</div> : ""}
        { message.subTitle ? <div className="subtitle">{message.subTitle}</div> : ""}
        { message.body ? <div className="body">{message.body}</div> : ""}
      </div>
    );

    setMessage( messageComponent );

    // Set image
    let imageComponent;

    const imagePath = objectGet( message, "image.path" );

    if( imagePath )
    {
      const host = getBackendHost();

      const logoUrl = `${host}/api/hk/media/get?path="${imagePath}"`;

      imageComponent = (
         <div className="image">
           <img src={logoUrl} alt=""/>
         </div>
       );

      setImage( imageComponent );
    }
    else{
      switch( messageLabel )
      {
        default:
          {
            imageComponent = (
               <div className="image">
                 <img src={generateSuggestion} alt=""/>
               </div>
             );
          }
      }
    }

    setImage( imageComponent );

  }, [ messageLabel ]);

  return (
    <div className="message">
      <Loader Image={Image} loading={messageLabel === "loading" ? true : false } />
      {Message}
    </div>
  );
}
