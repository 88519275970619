export const defaultMessageTimeout = 3000;
export const mapTilesUrl = process.env.REACT_APP_MAP_TILES_URL;

export const getBackendHost = () => {
  let backendHost;

  if( process.env.NODE_ENV === "development")
  {
    backendHost = "http://localhost:3001";
  }
  else{
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    backendHost = protocol + "//" + hostname + "";
  }

  return backendHost;
};
