// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Services
import FormBuilder from "../../helpers/FormBuilder";

// Helpers
import { validateWidgets } from "../../helpers/ValidateWidgets";

// Widgets
import Progress from "../../components/widgets/progress/Progress";
import Navigation from "../../components/widgets/navigation/Navigation";

// Stores
import { setPageValidation } from "../../store/pages/actions";
import { getCurrentPage } from "../../store/pages/selectors";
import { getAllFieldValues } from "../../store/formData/selectors";

// Styles
import "./DefaultPage.scss";

// -------------------------------------------------------------------- Function

/**
 * TaskPage component
 */
export default function DefaultPage()
{
  const dispatch = useDispatch();

  const { page, index } = useSelector(getCurrentPage);

  const { widgets } = page;

  const values = useSelector(getAllFieldValues);

  useEffect(() => {
    const { isValid, invalidFieldNames } =
      validateWidgets( widgets, values );

    dispatch(setPageValidation( index, { isValid, invalidFieldNames } ));
  }, [ values ]);

  return (
    <div className="task-page">
      <div className="content">
        <Progress />
        <div className="form">
          <FormBuilder widgets={widgets} />
        </div>
      </div>
      <Navigation />
    </div>
  );
}
