import { getCurrentPage, getPages }
  from "../../../store/pages/selectors";

import { useDispatch, useSelector } from "react-redux";

import "./Progress.scss";

/**
 * Progress component
 */
export default function Progress() {
  const { page } = useSelector(getCurrentPage);
  const pages = useSelector(getPages);
  const pathname = page.path;

  const activeItems = [];
  const openItems = [];
  let activeItem = null;

  for (const page of pages) {
    const { path, name } = page;

    if( !activeItem )
    {
      activeItems.push(
        <li className={pathname === path ? "active" : ""} key={path}>
          <div>{name}</div>
        </li>
      );

      if( pathname === path )
      {
        activeItem = page;
      }
    }
    else
    {
      openItems.push(
        <li key={path}>
          <div>{name}</div>
        </li>
      );
    }
  }

  return (
    <div className="progress">
      <h1>{activeItem.title}</h1>

      <div className="bar">
        <ul>{activeItems}</ul>

        <ul className="open">{openItems}</ul>
      </div>
      <div class="body" dangerouslySetInnerHTML={{__html:activeItem.body}}></div>
    </div>
  );

  return null;
}
